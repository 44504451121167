<template>
  <!-- 版本升级 -->
  <div class="box-card" style="text-align: left;">
    <!-- 头部 -->
    <div class="card_header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>运维中心</el-breadcrumb-item>
        <el-breadcrumb-item>版本管理</el-breadcrumb-item>
        <el-breadcrumb-item>版本升级</el-breadcrumb-item>
        <el-breadcrumb-item>升级</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="right">
        <el-button size="small" @click="goBack">返回</el-button>
        <el-button size="small" type="warning" @click="submitAddForm()" v-preventReClick>确定升级</el-button>
      </div>
    </div>
    <!-- 主要内容 -->
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="120px">
      <div class="title">选择设备版本</div>
      <el-divider></el-divider>
      <el-form-item label="设备类型：" prop="device_type">
        <el-select popper-class="my-select" v-model="addForm.device_type" placeholder="请选择设备类型" disabled @change="typeSelectChange">
          <el-option v-for="item in getOptions('DeviceType')" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备品牌：" prop="device_brand_id">
        <el-select popper-class="my-select" v-model="addForm.device_brand_id" placeholder="请选择设备品牌" @change="brandSelectChange">
          <el-option v-for="(item, index) in brandSelectList" :key="index" :label="item.brand_name" :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备型号：" prop="device_model_id">
        <el-select popper-class="my-select" v-model="addForm.device_model_id" placeholder="请选择设备型号" @change="modelSelectChange">
          <el-option v-for="(item, index) in modelSelectList" :key="index" :label="item.model_name" :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备版本：" prop="device_version_id">
        <el-select popper-class="my-select" v-model="addForm.device_version_id" placeholder="请选择设备版本" @change="versionSelectChange">
          <el-option v-for="(item, index) in versionSelectList" :key="index" :label="item.version_no" :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <div class="title">选择可升级设备</div>
      <el-divider></el-divider>
      <el-form-item class="el-form-item-devices" prop="devices" label-width="0">
        <el-table class="tablestyle" ref="multipleTable" :data="deviceDataList" tooltip-effect="dark" border stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="device_face_serial" label="序列号"></el-table-column>
          <el-table-column prop="device_face_name" label="设备名称"></el-table-column>
          <el-table-column prop="device_face_brand_name" label="设备品牌"></el-table-column>
          <el-table-column prop="device_face_model_name" label="设备型号"></el-table-column>
          <el-table-column prop="software_version" label="当前版本"></el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getBrandList, getModelList } from '../../../api/deviceApi'
import { getVersionSelectList, getDevicelsByModelList, setVersionUpgrade } from '../../../api/maintenanceCenter'
import { mapMutations, mapState, mapGetters } from 'vuex'
export default {
  data () {
    return {
      addForm: {
        device_type: 9, // 设备类型
        device_brand_id: '', // 设备品牌
        device_model_id: '', // 设备型号
        device_version_id: '', // 设备版本id
        device_version_no: '', // 设备版本号
        device_version_addr: '', // 设备版本文件地址
        devices: [
          // {
          //   device_serial: "", // 设备序列号
          //   device_name: "", // 设备名称
          //   device_version: "" // 设备版本
          // }
        ] // 设备列表
      },
      addFormRules: {
        device_type: [{ required: true, message: '请选择设备类型', trigger: 'blur' }], // 设备类型
        device_brand_id: [{ required: true, message: '请选择设备品牌', trigger: 'blur' }], // 设备品牌
        device_model_id: [{ required: true, message: '请选择设备型号', trigger: 'blur' }], // 设备型号
        device_version_id: [{ required: true, message: '请选择设备版本', trigger: 'blur' }], // 设备版本
        devices: [{ required: true, message: '请选择要升级的设备', trigger: 'blur' }] // 设备版本
      },
      // 设备品牌下拉框
      brandSelectList: [],
      // 设备型号下拉框
      modelSelectList: [],
      // 设备版本下拉框
      versionSelectList: [],
      // 设备列表
      deviceDataList: []
    }
  },
  created () {
    this.fnGetBrandSelectList()
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 返回页面
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    // 获取设备品牌下拉框
    async fnGetBrandSelectList (type) {
      const res = await getBrandList({
        device_type: this.addForm.device_type
      })
      this.brandSelectList = res && res.Code === 200 ? res.Data : []
    },
    // 获取设备型号下拉框
    async fnGetmodelSelectList (id) {
      const res = await getModelList({
        brand_id: id,
        device_type: this.addForm.device_type
      })
      this.modelSelectList = res && res.Code === 200 ? res.Data : []
    },
    // 获取设备版本下拉框
    async fnGetVersionSelectList () {
      const res = await getVersionSelectList({
        device_type: this.addForm.device_type,
        version_brand_id: this.addForm.device_brand_id,
        version_model_id: this.addForm.device_model_id
      })
      this.versionSelectList = res && res.Code === 200 ? res.Data : []
    },
    // 设备类型下拉框发生改变时
    typeSelectChange (v) {
      this.addForm.device_brand_id = ''
      this.addForm.device_model_id = ''
      this.addForm.device_version_id = ''
      this.addForm.device_version_no = ''
      this.addForm.device_version_addr = ''
      this.deviceDataList = []
      this.fnGetBrandSelectList(v)
    },
    // 品牌下拉框发生改变时
    brandSelectChange (v) {
      this.addForm.device_model_id = ''
      this.addForm.device_version_id = ''
      this.addForm.device_version_no = ''
      this.addForm.device_version_addr = ''
      this.deviceDataList = []
      this.fnGetmodelSelectList(v)
    },
    // 型号下拉框发生改变时
    modelSelectChange () {
      this.addForm.device_version_id = ''
      this.addForm.device_version_no = ''
      this.addForm.device_version_addr = ''
      this.deviceDataList = []
      this.fnGetVersionSelectList()
      this.fnGetDevicelsByModelList()
    },
    // 版本下拉框发生改变时
    versionSelectChange (v) {
      var item = this.versionSelectList.find(item => item._id === v)
      this.addForm.device_version_no = item.version_no
      this.addForm.device_version_addr = item.version_addr
    },
    // 获取设备列表
    async fnGetDevicelsByModelList () {
      const res = await getDevicelsByModelList({
        brand_id: this.addForm.device_brand_id,
        model_id: this.addForm.device_model_id
      })
      // console.log(res);
      this.deviceDataList = res && res.Code === 200 ? res.Data : []
    },
    // 当表格选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      console.log(val)
      this.addForm.devices = val.map(item => {
        return {
          device_serial: item.device_face_serial,
          device_name: item.device_face_name,
          device_version: item.software_version
        }
      })
    },
    // 提交表单验证
    submitAddForm () {
      console.log(this.addForm)
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          this.fnSetVersionUpgrade()
        } else {
          return false
        }
      })
    },
    // 升级请求
    async fnSetVersionUpgrade () {
      var obj = {
        device_version_id: this.addForm.device_version_id,
        device_version_no: this.addForm.device_version_no,
        device_version_addr: this.addForm.device_version_addr,
        devices: this.addForm.devices
      }
      console.log(obj)
      const res = await setVersionUpgrade(obj)
      if (res && res.Code === 200) {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.el-select {
  width: 500px;
}
.el-form-item-devices {
  margin-bottom: 0;
  .el-form-item__content {
    margin-left: 0px !important;
  }
}
.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 200px;
  }
}
</style>
